.Tabs .TabBar {
    display: flex;
    gap: 9px;
    margin-bottom: 24px;
}

.Tabs .Tab {
    color: var(--gray-dark);
    background-color: var(--gray-light);
    border-radius: 36px;
    padding: 8px 19px;
    font-size: 18px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    line-height: 1;
}

.Tabs .Tab.selected {
    color: var(--purple-dark);
    background-color: var(--purple-light);
}
.Tabs .Tab:hover {
    color: var(--link);
    background-color: var(--purple-light);
}
