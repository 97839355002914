.TextArea {
  flex: 1; /* same as TextField */
}
.TextArea > textarea {
  border: 1px solid var(--gray);
  border-radius: var(--gap-xs);
  box-shadow: var(--shadow-inset);
  /*
   * Disabled for now: undesirable impact on group home page. Need to decide
   * how to unify it w/ contact form
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-box-shadow);
  */
  color: var(--dark);
  font-size: 15px;
  height: 150px;
  padding: 10px 15px;
  resize: none;
  width: 100%;
  -webkit-text-size-adjust: none;
}

.TextArea > textarea::placeholder {
  color: var(--placeholder);
}

.TextArea.invalid > textarea {
  border-color: var(--error);
}

.TextArea .ErrorMessage {
  color: var(--error);
  font-size: 0.8em;
}

#SetWelcomeNote {
  .TextArea textarea {
    font-size: var(--font-lg);
    min-height: 5lh;
  }
}
