.moneyBox--wrap {
	border: 1px solid var(--purple-dark);
	border-radius: var(--gap-xs);
	box-shadow: inset rgba(125, 146, 224, 0.25) 3px 3px 3px 0;
	color: var(--purple-dark);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 60px;
	font-weight: 700;
	padding: var(--gap-sm);
	margin-top: var(--gap-xl);
}