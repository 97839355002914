/*/* ------------------------------------------------------------ *\
  Variables
\* ------------------------------------------------------------ */
$font-family-sans-serif: Arial, sans-serif;
$font-open-sans: 'Open Sans', sans-serif;
$font-ubuntu: 'Ubuntu', sans-serif;

$font-size-base: 15px;
// $font-size-base: 20px; OLD
$line-height-base: 1.4;

$responsive-xxs: 375px;
$responsive-xs: 768px;
$responsive-sm: 1024px;
$responsive-md: 1201px;

$mobile-small: '(min-width: #{$responsive-xxs}) ';
$mobile: '(min-width: #{$responsive-xs}) ';
$tablet-portrait: '(min-width: #{$responsive-sm}) ';
$small-desktop: '(min-width: #{$responsive-md}) ';
$retina: '(min-resolution: 2dppx) ';

// Usage
// @media #{$small-desktop}

:root {
  /* Colors */
  --text-old: #6E6E6E;
  // --text: #979797; Older
  --text: #50555C;
  --white: #fff;
  --purple: #97adff;
  --purple-darkest: #6a79b2;
  --purple-dark: #7d92e0;
  --purple-light: #e6e9ff;
  --purple-middle-light: #eff1fb;
  --gray: #ccc;
  --gray-lt: #f3f3f3;
  --gray-md: #e7e7e7;
  --gray-dk: #979797;
  --gray-txt: #A1A5AC;
  --black: #000;
  --black-light: #6E6E6E;
  --black-dark: #4a4a4a;
  --red: #e8465e;
  --red-light: #f7aab9;
  --title: #6E6E6E;
  --nav-background: #dadada;
  --highlight: #04b100;
  --green: #68d168;
  // --placeholder: #b2b2b2;
  --placeholder: #979797;
  --dark: #50555c;
  --error: #ed0006;
  --warning: #ff7982;
  --link: #7d92e0;
  --link-alt: #97adff;

  --faded-opacity: 0.5;

  /* Padding, spacing, etc */
  --list-item-spacing: 0.6em;
  --block-element-spacing: 1.225rem;
  --base-lh: #{$line-height-base};

  --shadow: 1px 1px 0px 0px rgba(195, 195, 195, 0.25);
  --shadow-inset: 1px 1px 4px 0px var(--gray-md) inset;

  /* https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-weight */
  --weight-thin: 100;
  --weight-extralight: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-extrabold: 800;
  --weight-black: 900;

  --gap-xs: 0.334rem;
  --gap-sm: 0.667rem;
  --gap-md: 1rem;
  --gap-lg: 1.25rem;
  --gap-xl: 1.5rem;
  --gap-2xl: 2rem;
  --gap-3xl: 2.667rem;
  --gap-4xl: 4.5rem;

  --font-base: 15px;
  --font-sm: 14px;
  --font-xs: 12px;
  --font-lg: 18px;
  --font-xl: 20px;
}
