.PaymentFormPaymentRequestButton {
    --pr-button-height: 40px;

    margin-top: var(--block-element-spacing);
    height: var(--pr-button-height);

}
.PaymentFormPaymentRequestButtonDisablingOverlay {
    background-color: var(--white);
    opacity: var(--faded-opacity);
    /* Match size of AP/GP button, stick on top of it */
    height: var(--pr-button-height);
    margin-top: calc(-1 * var(--pr-button-height));
}

.modern #ModalPaymentFormFooter {
    display: flex;
    justify-content: space-between;
    #ModalPaymentFormFooterSignIn {
        flex-grow: 1;
        text-align: start;
        order: 1;
    }
    #ModalPaymentFormFooterSwitchMode {
        flex-grow: 1;
        text-align: end;
        order: 2;
    }
}

.PaymentFormPaymentRequestButton {
  --pr-button-height: 40px;
  margin-top: var(--block-element-spacing);
  height: var(--pr-button-height);
}
.PaymentFormPaymentRequestButtonDisablingOverlay {
  background-color: var(--white);
  opacity: var(--faded-opacity);
  /* Match size of AP/GP button, stick on top of it */
  height: var(--pr-button-height);
  margin-top: calc(-1 * var(--pr-button-height));
}

////
#Overview {
  + .donate-form {
    margin-top: 1.75rem;

    h4 {
      display: none;
    }
  }
}
.donate-form {
  position: relative;  
  margin-top: 1.75rem;

  &.creditCard {
    padding-bottom: 105px !important;
  }

  .d-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    gap: 15px;  
    padding-top: 15px;

    div {
      border: 1px solid #CFCFCF;
      border-radius: 5px;
      box-shadow: var(--shadow);
      color: var(--dark);
      cursor: pointer;
      font-size: 17px;
      font-weight: 400; 
      line-height: 32px;      
      height: 35px;
      text-align: center;
      transition: all 100ms;

      &.active {
        background-color: var(--link);
        color: #fff;
        font-weight: 500;
      }
    }
  }

  p {
    letter-spacing: 0;
  }

  .TextField .ErrorMessage {
    color: var(--red);
    font-size: 14px;
    font-weight: 400;
    display: block;
    position: relative;
    margin-bottom: -7px;

    .SignInLink {
      margin-left: 2px;
      font-weight: 700;
    }
  }
}

// @media (max-width: 768.9px) {
//   .donate-form {
//     box-shadow: var(--shadow);
//   }
// }

