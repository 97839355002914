.add-contacts-non-gmail {

  .email-fields input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .error-message {
    color: red;
    font-size: 12px;
    text-align: center;
  }
}