.Heading {
  color: var(--purple-dark);
}
.modern .Heading {
  font-size: 26px;
}

#SetWelcomeNote .Heading {
    margin-bottom: 0.5rem;
}

h4 + .Heading {
  margin-top: 0.334rem;
}
