#Navigation {
    /* Stick to top of page */
    position: absolute;
    right: 0;
    /* Offset for header + border */
    top: calc(var(--header-height) - 1px);

    --nav-padding: 20px;
    background: var(--white);
    border-color: var(--nav-background);
    border-style: solid;
    border-width: 0 0 1px 1px;
    box-sizing: border-box;
    padding: var(--nav-padding);
    width: 276px;
}

#CreateGroupBanner {
    background-color: var(--purple-light);
    /* Expand the background to full width */
    margin: 0 calc(-1 * var(--nav-padding));
    padding: var(--list-item-spacing) var(--nav-padding);
}
