/**
 * WARNING: this goes to a modernized component. Further cleanup is required!
 */

 #BaseLayout .main > div.loader-heart {
    position: fixed;
    top: 20px;
    height: calc(100% - 70px);
 }

.loader-heart {
    position: fixed;
    left: 0;
    top: 70px; /* #MainHeader height */
    display: flex;
    flex-direction: column;
    width: 100%;
    // min-height: 100vh;

    // @media #{$mobile} {
    //     background: var(--purple-middle-light);
    // }

    &__container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 37px 50px;
        overflow: hidden;
        background: var(--white);
        align-items: center;
        justify-content: center;

        @media #{$mobile} {
            max-width: 415px;
            margin: 60px auto;
            border: 1px solid var(--white);
            border-radius: 25px;
        }
    }

    &__container-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 42px;
        margin-top: 1rem;
    }

    &__text {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
}

#root > .loader-heart {
  top: 0;
  height: 100%;
  padding: 1.125rem;

  .loader-heart__container {
    border-radius: 1rem;
  }
}

