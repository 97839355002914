#MyGroups {
  --circle-radius: 61px;
  background-color: var(--white);
  border-radius: var(--gap-md);
  // padding: var(--gap-md);

  .groupRowWrap {
    background-color: var(--purple-light);
    border-radius: 0.75rem;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.125);
    color: var(--dark);
    display: block;
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    text-decoration: none;

    div {
      font-size: 12px;
    }

    & > div:first-of-type {
      display: flex;
      align-items: center;
      gap: 0.33rem;
      margin-bottom: 0.33rem;      
    }

    h4 {
      color: var(--purple-dark);
      display: inline-flex;
      font-size: 1.2rem;      

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 206px;      
    }

    span {
      font-weight: 400;
    }

    &--inner {
      display: flex;
      gap: 0.75rem;
      margin-bottom: 0.25rem;

      div {
        display: inline-block;
        flex: 1;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }

    &--create {
      align-items: center;
      justify-content: space-between;  
      line-height: 1;    
      width: 100%;
      max-width: none !important;

      span {
        font-size: 2rem;
        margin-top: -2px;
      }
    }
  }
}

#MyGroups > h2 {
  margin-bottom: var(--gap-lg);
}

#MyGroups > ul {
  column-count: 2;
  column-fill: balance-all;
  list-style-type: none;
}
#MyGroups > ul > li {
  /* border: 3px dotted green; */
  break-inside: avoid-column;
  height: 250px;
  text-align: center;
}

#MyGroups .GroupListCircle {
  /* center the circle w/in the container*/
  margin: 0 auto;
  /* center contents within the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  /* circle background */
  background: var(--purple-light);
  border-radius: var(--circle-radius);
  height: calc(2 * var(--circle-radius));
  width: calc(2 * var(--circle-radius));
  /* content styling */
  color: var(--purple-dark);
  font-size: 2.5em;
}

#MyGroups .GroupListCaption {
  color: var(--purple-dark);
  font-weight: var(--weight-semibold);
}

#MyGroups .GroupListRole {
  color: var(--text-alt);
  font-size: smaller;
  font-weight: var(--weight-light);
}
