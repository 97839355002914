/**
 * There's PROBABLY a much better way to do this, but this sets up a grid
 * layout for the money components in the top area alongside the other text
 * pieces.
 */

// #GroupHomeV2 {
  #Overview {
    border: none;
    box-shadow: none;
    padding: 0;
    
    .OverviewTotal {
      display: grid;
      grid-template-areas:
          "sym amt frac"
          "sym amt invl";
      grid-template-columns: min-content min-content auto;
      line-height: 0.9;

      .ButtonV2 {
        display: flex;
        grid-column-end: none;
        align-self: flex-start;
        justify-self: flex-end;

        & .small {
          margin: 0;
        }
      }
    }

    .Money {
      color: var(--purple-dark);
      font-weight: 400;

      &.symbol {
        align-self: start;
        font-size: 30px;
        grid-area: sym;
        /* eyeballed until this looked right, line-heights are weird. */
        margin-block-start: 5px;
      }

      &.amount {
        font-size: 70px;
        grid-area: amt;
        letter-spacing: -1.75px;
      }   

      &.fraction {
        align-self: center;
        font-size: 20px;
        grid-area: frac;
      }         
    }  

    .interval {
      align-self: center;
      color: var(--gray-dark);
      grid-area: invl;
      margin-inline-start: 3px;
    } 

    .OverviewRow {
      color: var(--gray-light);
      margin-top: 14px;

      img {
        margin-right: 0.5rem;
      }

      .count {
        color: var(--dark);
      }  

      .groupMembers {
        color: var(--purple-dark);
        font-weight: var(--weight-normal);
      }  
      
      .organizerName {
        color: var(--dark);
      }                         
    }  

    .SectionHeaderLeading {
      color: var(--purple-dark);
      font-weight: 600;
    }

    .desc-container {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      // cursor: pointer;
    }

    .desc-container.collapsed {
      -webkit-line-clamp: 5; 
    }

    .desc-container.expanded {
      -webkit-line-clamp: none; 
    }  

    .desc-container + button {
      color: #7d92e0;
      margin: 0.33rem 0;
      width: 100%;
    }

    .ButtonV2.small {
      margin: 0;
    }

    #groupGoalProgress + .group-desc--wrap {
      margin-top: var(--gap-2xl);
    }
  }
