/* Note: this should get moved into Button directly. See https://app.asana.com/0/1204554029823374/1204749333804695/f */
.IconButton {
    border-radius: 5px;
/*    box-shadow: 0 3px 4px rgba(0,0,0,0.2);*/
    box-shadow: 0px 2px 3px 1px rgba(0,0,0,0.2);
    box-sizing: border-box;
    display: block;
    padding: 10px;
    width: 100%;
}
.IconButtonText {
    color: var(--text);
    font-weight: var(--weight-semibold);
    padding-left: 20px;
}


.share-link__wrap {
    border: 1px solid var(--gray);
    border-radius: var(--gap-xs);
    box-shadow: var(--shadow-inset); 
    font-size: 16px;
    font-weight: 400;       
    padding: 0.825rem 0.75rem;
    overflow-x: hidden;    
}

.inviteContacts p {
    font-size: 16px;
}
