#groupGoalProgress + .PayPad {
  margin-top: 2rem;
}

.PayPad {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  li {
      list-style-type: none;
  }
  button {
    background: var(--white);
    border-radius: 5px;
    border: 1px solid var(--gray);
    box-shadow: var(--shadow);
    color: var(--text);
    font-weight: 600;
    font-size: 15px;
    font-weight: 400;
    height: 35px;
    line-height: 32px;    
    width: 100%;
    transition: all .1s;
    &.active {
      background: var(--link);
      box-shadow: var(--shadow);
      color: var(--white);
    }
  }
}
