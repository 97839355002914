#PreviewBanner {
  // background-color: rgba(75,255,75,0.5);
  background-color: var(--purple);
  color: #fff;
  text-align: center;

  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  button {
    font-weight: 700;
  }
}

.publicMessage {
  background-color: rgb(125 146 224 / 20%);
  border-radius: 15px;
  padding: 1rem;
  margin: 1.25rem 0 1rem;  
  line-height: 1.35;

  .Subhead {
    padding-bottom: 0.5rem;
  }

  .desc-container {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    cursor: pointer;
  }

  .collapsed {
    -webkit-line-clamp: 5; 
  }

  .expanded {
    -webkit-line-clamp: none; 
  }  

  button {
    color: #7d92e0;
    margin: 0.33rem 0;
    width: 100%;
  }

  &.desc-container {
    margin-top: 0.825rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;

    // + .OverviewRow {
    //   margin-bottom: 1.5rem;
    // }
  }
}

// light yellow: #fff6cc
