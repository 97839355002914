.inviteByEmail {
    font-size: 16px;

    .link__wrap {
        overflow-x: hidden;
        display: flex;
        align-items: center;
        font-size: 13px;
        a{
            text-decoration: none;
            color:inherit;
        }
    }

    textarea{
        box-sizing: border-box;
        border: 1px dashed #B2B2B2;
        min-height:264px;
        padding: 1em;
        font-weight: 700;
        line-height: 140%;
        &:disabled{
            background: #EDF0FF;
            border: 1px dashed #97ADFF;
            /*color: #979797;*/
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    textarea::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    textarea {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }    

    .message{
        color: var(--purple-dark);
        font-weight: 600;
    }
}
