#groupGoalProgress {
  progress {
    background: transparent;
    border: 1px solid var(--purple-dark);
    border-radius: 0.5rem;
    height: 0.685rem;
    margin: 0.425rem 0 0.65rem;
    width: 100%;
  }

  progress::-webkit-progress-bar {
    background-color: #E6E9FF;
    border-radius: 0.5rem;
  }

  progress::-webkit-progress-value {
    background-color: var(--purple-dark);
  }  

  .font-bold {
    font-size: 16px;
  }  
}

#groupGoalProgress {
  + .donate-form {
    margin-top: 2.25rem;
  }
}