.TextField {
  /**
   * TextField should always be in a FormRow which has `display: flex;`
   * It's a little bit of a leaky abstraction, but this one is mostly
   * unavoidable.
   */
  flex: 1;
  /* define locally...may move upstream later */
  --field-padding: 16px;
}

/* Input wrapper */
.TextFieldInput {
  align-items: center;
  display: flex;
  font-size: var(--font-lg);
  transition: border-color 0.3s;
}

.TextFieldWithInnerElems {
  border: 1px solid var(--gray);
  border-radius: var(--gap-xs);
  box-shadow: var(--shadow-inset);  
}

/* Optional "before" element */
.TextFieldInputBefore {
  order: 1;
  padding-left: calc(0.25 * var(--field-padding));
}

.TextFieldInputElement {
  border: none;
  color: var(--dark);
  height: 48px;
  order: 2;
  padding: 0 var(--field-padding);
  width: 100%;
}

.TextField:not(.TextFieldWithInnerElems) .TextFieldInputElement {
  border: 1px solid var(--gray);
  border-radius: var(--gap-xs);
  box-shadow: var(--shadow-inset);
}

.TextFieldWithInnerElems .ErrorMessage {
  position: absolute;
}

.TextFieldWithInnerElems .TextFieldInputElement {
  height: 44px;
  margin: 2px 0;
}

.TextFieldInputElement::placeholder {
  color: var(--placeholder);
}

.TextFieldInputElement:not(:first-child) {
  /* Slim down padding if there's a "before" element */
  padding-left: calc(0.5 * var(--field-padding));
}

.TextFieldInputElement:autofill {
  /**
   * Note: Color will tend to differ as forced by browser.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/:autofill
   *
   * Intentionally empty
   */
}

@keyframes onAutoFillStart {
  from {background: #fff}
  to {background: red}
}

@keyframes onAutoFillCancel {
  from {background: red}
  to {background: #fff}
}

.TextFieldInputElement:autofill {
    // Expose a hook for JavaScript when auto fill is shown.
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillStart;

    // Make the backgound color become yellow _really slowly_
    transition: background 50000s ease-in-out 0s;
  }

  .TextFieldInputElement:not(:autofill) {
    // Expose a hook for JS onAutoFillCancel
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillCancel;
  }

/*
 * From old styles
input:-webkit-autofill {
   -webkit-text-fill-color: inherit;
   -webkit-box-shadow: 0 0 0 1000px #fff inset;
}
*/

/* Optional "after" element */
.TextFieldInputAfter {
  order: 3;
  padding-right: calc(0.5 * var(--field-padding));
}

.TextField.invalid .TextFieldInput {
  border-color: var(--error);
}

.TextField .ErrorMessage {
  position: absolute;
  color: var(--error);
  font-size: 0.75rem;
    margin-top: 5px;
}

.TextField.invalid {
  input {
    border-color: var(--error) !important;
  }
}
