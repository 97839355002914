/* TODO: incproprate some of these extra rules into List */
#AllowMembersToGroup ul {
    font-size: smaller;
}
#AllowMembersToGroup ul > li {
    cursor: pointer;
    user-select: none;
}
#AllowMembersToGroup ul > li:not(:first-child) {
    margin-top: calc(2 * var(--list-item-spacing));
}

.MemberToApproveRow {
    /* Checkbox / MemberInfo as two columns - this is enough */
    display: flex;
}
.MemberToApproveRow .MemberInfo {
    margin-left: var(--block-element-spacing);
}

.MemberToApproveRow.unselected {
    opacity: var(--faded-opacity);
}

.PhoneInfo .Verified {
    color: var(--highlight);
}
.PhoneInfo .Unverified {
    color: var(--warning);
}
