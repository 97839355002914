.slider-container {
  position: relative;
  width: 100%;
  height: 2rem;
  user-select: none;
}

.slider-bar {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: var(--purple-light);
  border: 1px solid var(--purple-dark);  
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.slider-fill {
  height: 100%;
  background-color: var(--purple-dark);
  border-radius: 5px 0 0 5px;  
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: var(--purple-dark);
  border-radius: 50%;
  cursor: pointer;
}

.slider-minMax {
  position: relative;
  top: 2.125rem;
  width: 100%;  
  display: flex;  
  align-items: center;
  justify-content: space-between;
  color: var(--purple-dark);
  font-size: 16px;
  font-weight: 700;
}


///////////

// .slider-container {
//   position: relative;
//   width: 100%;
//   height: 20px;
//   background-color: #ddd;
//   user-select: none; // Prevent text selection during dragging
// }

// .slider-bar {
//   height: 100%;
//   background-color: #007bff;
// }

// .slider-handle {
//   position: absolute;
//   top: 50%;
//   width: 10px;
//   height: 20px;
//   background-color: blue;
//   cursor: pointer;
//   transform: translate(-50%, -50%);
// }

