#TabBar {
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);
  margin: var(--gap-xl) 0 var(--gap-3xl);
  > * {
    flex-basis: content;
  }

  h4 {
    font-size: 20px;
    margin-bottom: var(--gap-sm);
  }

  section {
    cursor: pointer;
    border: 1px solid var(--gray);
    border-radius: var(--gap-xs);
    padding: var(--gap-md);
  }

  section.active {
    background-color: var(--purple-light);

    h4 {
      color: var(--purple-dark);
    }    
  }
}

.formWrap {
  position: relative;

  .Icon {
    position: absolute;
    background: #f3f4f4;  
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: inset 0 0 3px #C3C3C3;
    top: 24px;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    padding: 0 14px;
  }

  .TextField {
    input[type='password'] {
      padding-left: 56px;
    }
  }
}