.KeyValueList:not(:first-child) {
    margin-top: var(--block-element-spacing);
}

.KeyValueList dt {
    display: inline;
    font-weight: 400;
    padding-right: 0.5em;
}
.KeyValueList > div:not(:first-child) {
    margin-top: var(--list-item-spacing);
}
.KeyValueList dt::after {
    content: ':';
}
.KeyValueList dd {
    display: inline;
    font-weight: 600;
    /* This maybe belongs elsewhere, but addresses immediate overflow issues */
    word-break: break-all;
}
