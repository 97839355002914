.Icon {
    box-sizing: border-box;
}
.Icon.medium {
    height: 40px;
    width: 40px;
}
.Icon.small {
    height: 23px;
    width: 23px;
    /* this probably belongs to all of them eventually */
    vertical-align: top;
}

/*
 * Add padding after the icon so it doesn't bump into text
 */
.Icon.inline {
    /* eyeballed this value */
    padding-inline-end: 0.2lh;
}

.Icon.lock {
    padding-bottom: 4px;    
    margin-right: 5px;
}
