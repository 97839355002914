/* Base styling for all buttons */
#GroupHomeV2 .ButtonV2 {
    display: inline-block;
    font-weight: var(--weight-semibold);
    text-decoration: none;
}

/* Sizes */
#GroupHomeV2 .ButtonV2.small:not(.minimal) {
    border-radius: 12px;
    font-size: 11px;
    padding: 5px 17px;
    vertical-align: text-bottom;
}
#GroupHomeV2 .ButtonV2.large:not(.minimal) {
    border-radius: 20px;
    font-size: 1rem;
    padding: 10px;
}
#GroupHomeV2 .ButtonV2.large {
    /* Large buttons are also full-width blocks, regardless of minimal */
    display: block;
    margin-top: var(--gap-xl);
    margin-bottom: var(--gap-xs);
    text-align: center;
    width: 100%;
}

/* Intents (which modify color) */
#GroupHomeV2 .ButtonV2.none {
    background-color: var(--purple-light);
    color: var(--purple-dark);
}
#GroupHomeV2 .ButtonV2.primary {
    background-color: var(--purple);
    color: var(--white);
}
#GroupHomeV2 .ButtonV2.success {
    color: var(--white);
    background-color: var(--green);
}

/* Disabled state */
#GroupHomeV2 .ButtonV2:disabled, #GroupHomeV2 .ButtonV2.disabled  {
    background-color: var(--gray-lightest);
    color: var(--white);
    cursor: not-allowed;
}

/* Minimal overrides */
#GroupHomeV2 .ButtonV2.minimal {
    background: none;
    /* font-size: 15px; */
    font-weight: var(--weight-light);
}

#GroupHomeV2 .ButtonV2.minimal.none { color: var(--purple-dark); } /* unchanged */
#GroupHomeV2 .ButtonV2.minimal.primary { color: var(--purple); }
#GroupHomeV2 .ButtonV2.minimal.success { color: var(--green); }
#GroupHomeV2 .ButtonV2.minimal.disabled { color: var(--gray); }
