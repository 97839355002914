.Subhead {
    color: var(--purple-dark);
}
.Subhead:not(:first-child) {
    margin-top: var(--gap-2xl);
}

.modern .Subhead {
    color: #50555C;
    font-size: 15px;
}
