.List {
    list-style-type: none;
}
.List:not(:first-child) {
    margin-top: var(--block-element-spacing);
}

.List > .ListItem:not(:first-child) {
    margin-top: var(--list-item-spacing);
}
