/* Basic link styling - inherit color/size/etc */
.AnchorButton {
    color: var(--link);
    text-decoration: none;
}
/*.AnchorButton:hover:not(:disabled) {
    text-decoration: underline;
}*/


/* Intents can modify color */
.AnchorButton.intent-none {
}
.AnchorButton.intent-secondary {
    color: var(--text-second);
}
.AnchorButton.intent-warning {
    color: var(--warning);
}
