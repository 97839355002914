/**
 * Since we can't use native fields here, we need to replicate some of the
 * styling from TextField and FormRow
 */
.CreditCardFormNumber {
    flex: 1;
}
.CreditCardFormExpiryAndCode {
    display: flex;
    flex: 1;
    gap: var(--list-item-spacing);
}

.CreditCardFormExpiry {
    flex: 2;
}
.CreditCardFormCode {
    flex: 1;
}

/**
 * CSS classes outside of the Stripe iframe. Class names configured in
 * CreditCardForm and put into the DOM by Stripe.
 */

.CreditCardFormStripeBase {
    padding: 12px 15px;
    box-shadow: inset 1px 1px 2px #d9d9d9;    
    border: 1px solid #CFCFCF;
    border-radius: 5px;      
}
.CreditCardFormStripeInvalid {
    border-color: var(--error);
}

.donate-btn--wrap {
  position: absolute;
  bottom: 10px;
  width: 100%;
  // width: calc(100% - 40px);

  &--links {
    display: flex;
    justify-content: space-between;
  }
}

.creditCard {
  .PaymentBreakdown {
    margin-top: 2rem;
  }
}

#ChangeSubscription {
  .donate-btn--wrap {
    position: relative;
    padding-top: 2rem;

    + .PaymentBreakdown {
      margin-top: 0;
    }    
  }
}