img.Loader {
    height: 30px;
    margin: 0 auto;
    padding: 20px;
    width: 30px;

    /* FIXME: inheritance hacks */
    box-sizing: content-box;
    display: flex;
}

#BaseLayout.modern .main img.Loader {
    width: 25vw;
    max-width: 120px;
    height: calc(100vh - 184px);
}
