.Section {
  & > header {
    display: flex;
    margin-bottom: 0.5rem;
  }

  & > footer {
    display: flex;
  }

  & > header h3,
  .SectionHeaderLeading span {
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-weight: var(--weight-semibold);
    color: var(--purple-dark);
  }
}

// Moving this out allows starting to add the <Section> to non-converted pages without disruption
#BaseLayout.legacy .Section,
#GroupHomeV2 .Section,
.hasSections .Section {
  background: var(--white);
  border-radius: 15px;
  box-shadow: var(--shadow);
  padding: 1rem;
}

.hasSections .Section {
  padding: 1.75rem 1.5rem;
}

.SectionHeaderTrailing,
.SectionFooterTrailing {
  /* this will right-align on LTR languages, and left-align on RTL */
  margin-inline-start: auto;
}


