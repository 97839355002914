.faqWrap {
	h4 {
		color: var(--purple-darkest);
		font-size: 1.2rem;
		margin: 0.5rem 0;
	}

	section {
		padding: 0.25rem 0 1.5rem;
	}

	section:not(:first-child) h4 {
		margin: 1rem 0 0.5rem;
	}

	a {
		color: var(--purple-darkest);
	}
}