.circle-number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;  
  background-color: var(--purple);
  color: #fff;
  font-size: 1.33rem;
  font-weight: 700;
  width: 38px;
  height: 38px;
}