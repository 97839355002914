.Link {
    color: var(--link);
    text-decoration: none;
    &.minimal {
        color: #979797;
    }
}
.Link:hover {
    text-decoration: underline;
}

.Link.button {
    /* Copied from button styles */
    background: var(--purple);
    color: var(--white);
    display: block;
    text-align: center;
    width: 100%;
    &:hover {
        text-decoration: none;
    }
}

.legacy .Link.button {
    border-radius: 36px;
    box-shadow: 0 5px 0 var(--purple-dark);
    font-size: 26px;
    font-weight: 700;
    padding: 14px 20px;
    transition: box-shadow 0.2s, transform 0.2s;

    &:hover {
        box-shadow: none;
        transform: translateY(5px);
    }
}

.Link.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.modern .Link.button {
    border-radius: 20px;
    font-weight: 600;
    font-size: 15px;
    padding: 12.5px 0; // (40 - 15)/2
    line-height: 1;
    &.tab {
        font-size: 14px;
        display: inline;
        line-height: 140%;
        padding: 4px 16px;
        background-color: var(--purple-light);
        font-weight: 700;
        color: var(--purple-dark);
        &.active {
            cursor: default;
        }
        &:not(.active) {
            background-color: var(--gray-md);
            color: #858585;
            font-weight: 400;
        }
    }
}
