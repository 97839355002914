/* Copy of Link/index.css... to unify... */
.Anchor {
    color: var(--link);
    text-decoration: none;
    /* color: var(--link-alt); */
}
.Anchor:hover {
    text-decoration: underline;
    /* color: var(--highlight); */
}

.no-dec {
    color: var(--text);
}
    