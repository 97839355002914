.MoneyInputCurrencySymbol {
  color: var(--placeholder);
  font-weight: var(--weight-bold);
}

.MoneyInputPerMonth {
  color: var(--placeholder);
  font-size: smaller;
}

.amountInput--wrap {
  position: relative;
  margin-top: 15px;

  .amountInput {
    // color:#fff;
    // caret-color: var(--link);
    width: 100%;
    height: 46px;
    box-shadow: inset 1px 1px 2px rgba(217, 217, 217, 0.6) !important;    
    border: 0.75px solid var(--purple-dark) !important;
    border-radius: 5px !important;  
    color: var(--link);
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    padding: 0.5rem 0.5rem 0.5rem 3.334rem; 
  } 

  .amountInput::placeholder{
    color: #fff;
  }   

  .ErrorMessage {
    color: var(--red);
    font-size: 13px;
  }  
}

.amountInput--wrap:before,
.amountInput--wrap:after {
  // content: '$';
  content: url('../../assets/images/dollar-sign.svg');
  color: var(--gray-dk);
  font-size: 1.133rem;
  font-weight: 400;
  position: absolute;
  left: 10px;
  z-index: 100;
}

.amountInput--wrap:before {
  background-color: var(--purple-light);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;    
  border-right: 0.5px solid var(--purple-dark);
  width: 43px;
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
  line-height: 3.5;
  text-align: center;
}

.amountInput--wrap:after {
  color: var(--purple-dark);
  content: 'each month';
  top: 14px;
  left: unset;
  right: 10px;
  font-size: 0.875rem;
}
