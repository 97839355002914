.OverflowableMessage {
    --fade-effect: 1.5lh;
}
.OverflowableMessage.closed > .OverflowableMessageBody {
    max-height: 4lh;
    overflow-y: hidden;
}

.OverflowableMessage > .OverflowableMessageOpenCloseLink {
    text-align: center;
}
.OverflowableMessage.closed > .OverflowableMessageOpenCloseLink::before {
    background: linear-gradient(to bottom, transparent, var(--white));
    content: "";
    display: block;
    height: var(--fade-effect);
    margin-top: calc(-1 * var(--fade-effect));
    position: relative;
}

.OverflowableMessageBody .UserText {
    overflow-wrap: anywhere;
}
