#GroupHomeV2 #Messages footer {
    margin-top: 20px;
}
#GroupHomeV2 #Messages cite {
    display: block;
    font-style: normal; /* should this be in reset.css? */
    font-weight: var(--weight-normal);
    margin-bottom: 3px; /* who needs line-height? */
}
#GroupHomeV2 #Messages small {
    color: var(--gray-light);
    font-size: inherit;
}
