/* ------------------------------------------------------------ *\
  Base
\* ------------------------------------------------------------ */
html {
  font-size: $font-size-base;
}

body {
  min-width: 320px;
  font-family: $font-open-sans;
  font-weight: var(--weight-light);
  line-height: $line-height-base;
  color: var(--text);

  // color: var(--dark);
  // font-size: 15px;

  // line-height: 1.4;
  // font-family: "Open Sans";

  hr {
    // border: none;
    border-top: 1px solid var(--gray-ltest);
    margin: 1rem 0;
  }
}

button {
  appearance: none;
  border: none;
  background: none;
}

strong {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-ubuntu;
  font-weight: 500;
  // color: var(--title);
}

h2,
.h2 {
  font-size: 26px;
  letter-spacing: 0.33px;
  line-height: 1.25;
  font-weight: 600;
}

h3,
.h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}

h4,
.h4 {
  color: var(--text);
}

h5,
.h5 {
  font-family: $font-open-sans;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0.375rem;
}

h6,
.h6 {
  font-size: 20px;
  font-weight: 600;
  font-family: $font-open-sans;
}

.Link {
  color: var(--link);
  text-decoration: none;
  &.minimal {
    color: #979797;
  }
  &.bold {
    font-weight: 500;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.errorMsg {
  color: var(--red);
}

p.heavier {
  font-size: 16px;
  font-weight: 400;
}

.color-primary {
  color: var(--purple-dark);
}

.font-w-thin {
  font-weight: 300;
}

.font-w-reg {
  font-weight: 400
}

.font-w-500 {
  font-weight: 500;
}

.font-semibold {
  font-weight: var(--weight-semibold);
}

.font-bold {
  font-weight: 700;
}

.font-small {
  font-size: 0.75rem;
}

.font-16px {
  font-size: 16px;
}

.font-1rem {
  font-size: 1rem;
}

.no-top-mrg {
  margin-top: 0 !important;
}

.pd-btm--md {
  padding-bottom: var(--gap-md);
}

.pd-top-btm--md {
  padding-bottom: var(--gap-md);
  padding-top: var(--gap-md);
}

.pd-lfrt {
  padding-left: var(--gap-md);
  padding-right: var(--gap-md);
}

.pd-lfrt--lg {
  padding-left: var(--gap-lg);
  padding-right: var(--gap-lg);
}

.pd-rt--sm {
  padding-right: var(--gap-sm) !important;
}

.mg-rt--xs {
  margin-right: var(--gap-xs) !important;
}

.mg-rt--sm {
  margin-right: var(--gap-sm) !important;
}

.mg-top--xs {
  margin-top: var(--gap-xs) !important;
}

.mg-top--sm {
  margin-top: var(--gap-sm) !important;
}

.mg-top--md {
  margin-top: var(--gap-md) !important;
}

.mg-top--lg {
  margin-top: var(--gap-lg) !important;
}

.mg-top--2xl {
  margin-top: var(--gap-2xl) !important;
}

.mg-top--gap-3xl {
  margin-top: var(--gap-3xl) !important;
}

.mg-btm--xs {
  margin-bottom: var(--gap-xs) !important;
}

.mg-btm--sm {
  margin-bottom: var(--gap-sm) !important;
}

.mg-btm--md {
  margin-bottom: var(--gap-md) !important;
}

.mg-btm--lg {
  margin-bottom: var(--gap-lg) !important;
}

.mg-btm--2xl {
  margin-bottom: var(--gap-2xl) !important;
}

.mg-btm--3xl {
  margin-bottom: var(--gap-3xl) !important;
}

.mg-lf--xs {
  margin-left: var(--gap-xs) !important;
}

.mg-lf--sm {
  margin-left: var(--gap-sm) !important;
}

.sib--no-mg + * {
  margin: 0 !important;
}

.sib--top-mg-sm + * {
  margin-top: var(--gap-sm) !important;
}

.lh-1 {
  line-height: 1.1;
}

.d-flex {
  display: flex;
}

.j-between {
  justify-content: space-between;
}

.j-center {
  justify-content: center;
}

.a-center {
  align-items: center;
}

.flex-gap-xs {
  gap: var(--gap-xs)
}

.text-center {
  text-align: center;
}

.w-full {
  width: 100%;
}

.success-green {
  color: var(--green);
}

.success-purple {
  color: var(--purple-dark);
}

.centered-svg {
  margin: 3rem auto 1rem;
  width: 100%;
  max-width: 320px;
}

.checklist-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1.25rem;

  .Icon {
    padding-top: 3px;
  }    
}

#SignUpWithGoogle {
  div {
    border-color: #1f1f1f;
  }

  span {
    color: #1f1f1f;
    // font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
  }

  iframe {
    width: 250px !important;
    margin: 0 auto !important;
    transform: translateX(18px);    
  }
}

#GoogleSignupEmail {
  .ErrorMessage {
    position: relative;
  }

  .SignInLink {
    font-weight: 500;
  }
}

.checklist-wrap--text {
  display: flex;
  flex-direction: column;

  .P {
    color: var(--gray-dk);
    font-weight: var(--weight-normal);
    padding-bottom: 2px;
  }

  span {
    color: var(--text);
    font-weight: var(--weight-semibold);      
  }

   + span {
    color: var(--text);
  }

  h4 {
    color: var(--dark);
    font-size: 1.25rem;
  }
}

img[alt^="checked"] + .checklist-wrap--text {
  span {
    color: #000;
  }
}

@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
}