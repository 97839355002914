.PaymentBreakdown:not(:first-child) {
    margin-top: var(--block-element-spacing);
}

.PaymentBreakdown dl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: calc(0.4 * var(--block-element-spacing));
}
.PaymentBreakdown dl > dt {
    color: var(--text-alt);
}

.PaymentBreakdown dl.Total {
    border-top: 1px solid var(--text-second);
    padding-top: calc(0.4 * var(--block-element-spacing));
}
