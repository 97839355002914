.UserText {
  // padding-bottom: var(--gap-md);
  // border-bottom: 1px solid var(--gray-md);
  // margin-bottom: var(--gap-md);
  white-space: break-spaces;
}

.messages-group {
  .UserText {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.has-more {
  .UserText {
    padding-bottom: var(--gap-md);
    border-bottom: 1px solid var(--gray-md);
    margin-bottom: var(--gap-md);
    white-space: break-spaces;
  }
}
