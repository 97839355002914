#GroupHomeV2 {
  position: relative;

  #GroupSelector {
    width: fit-content;
    color: var(--gray-middle);
    font-weight: var(--weight-semibold);
    padding: 0.33rem 1rem 0;
    // width: fit-content;

    .header-wrap {
      display: inline-flex;
      gap: 4px;
      align-items: center;      
      width: 100%;
      background-color: var(--white);
      border-bottom: 1px solid transparent;
      padding-bottom: var(--gap-xs);
    }

    .name {
      color: var(--purple-dark);
      margin-inline-end: 4px;

      max-width: 155px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;      
    } 

    span,
    .ButtonV2,
    button {
      position: relative;
      z-index: 100;
    }

    .ButtonV2.disabled {
      background-color: var(--gray);
    }

    button {
      margin: 0;
    }

    .ButtonV2 {
      margin-right: var(--gap-xs);
    }

    .dropdown-trigger {
      // float: right;
      padding: 0 2px;
      width: 21px;
      height: 22px;
      margin-top: -3px; 
      margin-right: -3px; 

      img {
        transition: all 100ms;
      }
    }

    .dropdown-wrap {
      position: absolute;
      width: 100%;
      top: 0.5rem;      
      left: 0;
      border-radius: 15px;      
      max-height: 0;
      overflow: hidden; 
      padding: 0 15px;     
      transition: all 100ms;
      z-index: 0;

      a {
        display: block;
        text-decoration: none;
      }
    }  

    .isOpen {
      .header-wrap {
        border-bottom: 1px solid var(--gray-md);
        // padding-bottom: 2.5px;
      }      

      .dropdown-trigger {
        img {
          transform: rotate(180deg);
        } 
      }

      .dropdown-wrap {
        top: -2.5px;
        height: fit-content;
        max-height: 800px;
        padding: 2.125rem 15px 6px;
        margin-top: 2.5px;
        
        background: var(--white);
        box-shadow: 1px 1px 0px 0px rgba(195, 195, 195, 0.75);   

        a:first-child,
        span:first-child {
          padding-top: 6.5px;
        }    
      }

      .dropdown-item {
        padding: 2.5px 0;
      }

      a.dropdown-item {
        color: var(--purple-dark);
      }

      span.dropdown-item {
        color: var(--gray-dk);
        display: block;
      }

      span.dropdown-item::before {
        content: "\2022"; /* Unicode bullet character (disc) */
        margin-right: 5px; /* Adjust spacing as needed */
      }
    } 
  } 
}