#MainHeaderNav {
	button {
		img {
			transition: all 200ms;
		}

		&.isOpen {
			img {
				transform: rotate(180deg);
			}
		}
	}
}