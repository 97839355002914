.add-contacts-gmail {
  flex: 1;
}

.add-contacts-gmail,
.load-contacts-gmail {
  background-color: white;
  border-radius: 15px;
}

.load-contacts-gmail {
  max-width: 600px;

  form {
    max-height: 740px;
    // overflow-y: auto;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */      
  }

  form::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    form {
      max-height: calc(100vh - 110px);
      overflow: hidden;
    }    
  }

  .load-contacts-header {
    border-radius-top-left: 15px;
    border-radius-top-right: 15px;
    padding: 1.125rem;
    border-bottom: 1px solid var(--gray);
  }

    @media only screen and (max-width: 768px) {
      .load-contacts-header {
        display: none;
      }    
    }  

  .to-field {
    font-size: 1rem;
    font-weight: 400;
    // border-bottom: 1px solid var(--gray);

    align-items: center;
    border: 1px solid #B2B2B2;
    border-radius: 2rem;
    margin-top: 1rem;

    position: sticky;
    top: 0;
    background: white;

    span {
      font-size: 2.75rem;
      position: absolute;
      margin: -0.1rem 0 0 -0.825rem;   
      color: #B2B2B2;
      transform: rotate(270deg);    
    }

    .FormRow {
      width:100%;
      margin-top: 0;
      padding-top: 0.5rem;
    }
  }

  .TextArea {
    // padding-bottom: 0.5rem
  }

  .TextArea > textarea {
      border: none;
      box-shadow: none;
      // color: var(--purple-dark);
      font-size: 1.05rem;
      font-weight: 400;
      letter-spacing: -0.5px;
      padding: 0 1rem 0 1.5rem;
      margin-top: 2px;
      display: inline-table;
      height: 3ch;
      &::placeholder{
        color: var(--text);
      }
  } 

  .contacts-list {  
    // max-height: 575px;
    // height: 475px;
    height: auto;
    overflow: scroll;
    overflow-x: hidden;
    cursor: pointer;
    padding: 0 1.125rem;

    .contact-header {
      display: none;
      background-color: var(--gray-md);
      height: 30px;        
      font-size: 19px;
      font-weight: 400;
      padding-left: 1.125rem;
    }

    .contact-header:has(+ .contact-item) {
      display: block;
    }

    .contact-item {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-start;
      align-items: center;
      padding: 0.625rem 0.5rem;
      // border-bottom: 1px solid #e0e0e0;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.33;

      opacity: 1;
      transition: opacity 165ms ease-in-out;

      &.odd {
        background-color:#EFF1FB;
      }
      &.even {
        background-color:#f7f7f7;
      }
      .contact-name {
        color: var(--black-dark);
      }
      .contact-email {
        font-size: 13px;
      }
    }

    .contact-item.fadeHide {
      opacity: 0;
      pointer-events: none;
    }

    .selected-contacts {
      border-bottom: 1px solid var(--gray-dk);
      font-size: 17px;
      font-weight: 400;      
      padding-bottom: var(--gap-xs);
      margin-left: var(--gap-xs);      
      margin-top: var(--gap-xl);
      margin-bottom: var(--gap-sm);
    }
  }

  .contacts-list.one {
    margin-top: -2.75rem;

    & > .contact-item:first-of-type {
      padding-top: 2.25rem;
    }
  }

  .contacts-list.two {
    padding-bottom: 2.5rem
  }

  .contacts-list.hide {
    display: none;
  }

  .error-message {
    color: red;
    margin-top: 10px;
  }
  .sticky {
    position: sticky;
    bottom: 0;
    background-color: white;
    border-top: 1px solid var(--gray);
    padding: 0 15px 15px;
    border-radius: 0 0 15px 15px;
  }
}

.invite-contacts-header {
  position: sticky;
  top: 0;
  // background-color: #fff;
  padding: 1rem 1.125rem;
}

@media only screen and (min-width: 769px) {
  .load-contacts-gmail {
    min-width: 375px;
    width: 100%;
  }

  .load-contacts-gmail .contacts-list {
    min-height: 460px;
  }
}


@media only screen and (max-width: 768px) {
  .load-contacts-gmail {
    // Old Styles to make gmail list full width
    // border-radius: 0;
    // width: 100vw;
    // top: -1rem;
    // left: -1rem;
    padding-top: 0.75rem;

    .contacts-list {
      height: calc(100vh - 300px);
      // padding-bottom: 127px;
    }
  }
}