#Home {
  & > ol {
    counter-reset: signup-item;
  }
  & > ol > li {
    margin-top: calc(2 * var(--list-item-spacing));
    color: var(--black);
    list-style-type: none;
  }
  & > ol > li:before {
    counter-increment: signup-item;
    content: counter(signup-item, decimal) ") ";
  }

  & > img {
    display: block;
    margin: 40px auto 0;
  }

  & > ol {
    counter-reset: signup-item;
  }

  .checklist-wrap {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: 1.25rem;

    .Icon {
      padding-top: 3px;
    }    
  }

  // .checklist-wrap--text {
  //   display: flex;
  //   flex-direction: column;

  //   .P {
  //     color: var(--gray-dk);
  //     font-weight: var(--weight-normal);
  //     padding-bottom: 2px;
  //   }

  //   span {
  //     color: #000;
  //     font-weight: var(--weight-semibold);      
  //   }
  // }
}
// #Home > ol > li {
//     margin-top: calc(2 * var(--list-item-spacing));
//     color: var(--black);
//     list-style-type: none;
// }
// #Home > ol > li:before {
//     counter-increment: signup-item;
//     content: counter(signup-item, decimal) ") ";
// }

// #Home > img {
//     display: block;
//     margin: 40px auto 0;
// }





// checklist-wrap
