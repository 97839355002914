#GroupHomeV2 {
  .MembersRow {
    display: flex;

    &.pending {
      color: var(--gray-md-light);

      .MembersRowAmount {
        color: #81da80;
      }    
    }    
  }

  #Messages {
    article {
      padding-top: var(--gap-xs);
      margin-bottom: var(--gap-lg);
    }

    .ButtonV2.large {
      margin-top: var(--gap-sm);
      margin-bottom: var(--gap-md);
      min-height: 40.5px;

      span {
        display: flex;
        place-content: center;        
      }
    }
  }
}

#GroupHomeV2 .MembersRowAmount {
    color: var(--highlight);
    font-weight: var(--weight-normal);
    width: 3.5rem;
    /* width: 80px; */
    display: flex;
}
#GroupHomeV2 .MembersRowAmount .Money.amount {
    text-align: end;
    width: 40px;
}
#GroupHomeV2 .MembersRowSeparator {
    padding-inline-start: 8px;
    padding-inline-end: 4px;
}
#GroupHomeV2 .MembersRowAllow {
    margin-inline-start: auto;
}


#GroupHomeV2 .members {
  border-bottom: 1px solid #ccc;
  padding-bottom: var(--gap-md);
}

.empty-section--home {
  text-align: center;
  padding: 2.75rem 1rem;
}


.spinnerSmall {
  width: 63px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  span {
    max-height: 14px;
  }

  svg {
    transform: scale(.7);
    margin-top: -1px;
  }
}

