.Button,
.ButtonV2.large {
  display: block;
  width: 100%;
  background: var(--purple);
  // border: 1px solid var(--purple);
  border-radius: 36px;
  color: var(--white);
  font-size: var(--font-base);
  font-weight: var(--weight-semibold);
  line-height: 1.3;
  padding: var(--gap-sm) var(--gap-lg);
  text-align: center;
  transition: transform 0.2s;
}

a.Button,
a.ButtonV2.large {
  margin: 1.25rem 0;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .Button,
  .ButtonV2 {
    // max-width: 320px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
   @keyframes buttonTranslate {
    0% {
      transform: translateY(0); /* Initial state */
    }
    50% {
      transform: translateY(2px); /* Move the button down */
    }
    100% {
      transform: translateY(0); /* Return to the original position */
    }
  }

  // .ButtonV2:hover:not(:disabled) {
  //   animation: buttonTranslate 250ms ease-out forwards;
  //   box-shadow: none; /* Box-shadow reset */
  // }
  .Button:hover:not(:disabled),
  .ButtonV2:hover:not(:disabled) {
    box-shadow: none;
    animation: buttonTranslate 175ms ease-out forwards;
  }
}

.Button:disabled,
.ButtonV2:disabled {
  opacity: var(--faded-opacity);
}


/// From Eric's Code ///
.legacy .Button {
    border-radius: 36px;
    box-shadow: 0 5px 0 var(--purple-dark);
    font-size: 26px;
    font-weight: 700;
    padding: 14px 20px;
    transition: box-shadow 0.2s, transform 0.2s;
}
.modern .Button {
    border-radius: 20px;
    /* background: #97ADFF; */
    font-weight: 600;
    font-size: 15px;
    height: 40px;
    &.pill {
        padding: 2px 16px;
        height: 18px;
        background-color: #E6E9FF;
        border-radius: 10px;
        font-weight: 600;
        font-size: 10px;
        line-height: 140%;
        text-align: center;
        color: #7D92E0;
        &.save {
          background-color: #68D168;
          color:white;
        }
      }
}

.legacy .Button:hover:not(:disabled) {
    box-shadow: none;
    transform: translateY(5px);
}

.google-btn {
  position: relative;
  background-color: #fff;
  border: 1px solid #1f1f1f;
  border-radius: 2rem;
  color: #000;
}

.google-btn > img {
  position: absolute; 
  left: 1rem;   
}

