:root {
    /* I'm intentionally keeping this away from the main set of variables since
     * it's only used externally in a crazy hack on GroupHomeV2. Once we're
     * able to fix the need for that, we can re-scope this back into
     * #MainHeader. See #765.
     */
  --header-height: 70px;
}
html {
/*    background: var(--white);*/
  background-color: var(--purple-middle-light);
}

#BaseLayout .main {
  position: relative;
  background: var(--white);
  border-radius: 25px;
  max-width: 415px;
  padding: 1.875rem;
}

#BaseLayout .main > div {
  position: relative;
}

#BaseLayout .main.groupHome,
#BaseLayout .main.hasSections {
  background-color: transparent;
  padding: 0;
}


#BaseLayout.legacy .main {
  background: var(--white);
  margin: 4rem auto;
  padding: 2rem;
  width: 100%;
}

#BaseLayout.modern .main {
  /* override a couple vars for now until this gets actually standardized */
  margin: 30px auto;
  max-width: 375px; 

  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);

  color: var(--dark);
  font-size: 15px;
  font-weight: var(--weight-light);
  line-height: 1.4;

  hr {
    border: none;
    border-top: 1px solid var(--gray);
    margin: 1rem 0;
  }

  .mg-btm--xs + hr {
    margin-top: 0.334rem;
  }
}

#BaseLayout.modern {
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

/**
 * Mobile overrides
 */
@media(max-width: 767px) {
  #BaseLayout.legacy .main {
    border-radius: 0;
    margin: 0;
    max-width: 100%;
    min-height: calc(100vh - var(--header-height));
    // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
    min-height: -webkit-fill-available;
  }
  #BaseLayout.modern .main {
    max-width: 100%;
    // overflow-x: hidden;
    flex: 1;
    // min-height: calc(100vh - 100px);
    margin: 1rem;
  }
}

/**
 * Desktop overrides
 */
@media(min-width: 768px) {
  #BaseLayout.modern .main {
    min-width: 415px;
  }
}

#Messages > .Message {
  align-items: center;
  color: var(--white);
  display: flex;
  font-size: 1.2em;
  font-weight: var(--weight-semibold);
  height: 70px;
  justify-content: center;
}

#Messages > .Message.error {
  background-color: var(--red-light);
  display: block;
  text-align: center;
  padding-top: 0.5rem;
}

#Messages > .Message.success {
  background-color: var(--green);
}

#MainHeader {
  align-items: center;
  background: var(--white);
  border-bottom: 1px solid var(--nav-background);
  display: flex;
  height: var(--header-height);
  position: sticky;
  top: 0;
  /* This is translated from an older value, not significant */
/*  --side-item-width: 70px;*/

  /* Keep above rest of page. Elements randomly float over top without. See
   * commit history on #739.
   */
  z-index: 101;
}


#MainHeaderBackButton {
/*    order: 1;*/
/*    width: var(--side-item-width);*/
  flex: 2;
  padding-left: 20px;
  box-sizing: border-box;
}
#MainHeaderLogo {
  flex: 10;
  // filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  /* Fill remaining space */
/*    order: 2;*/
  text-align: center;
}

#MainHeaderNav {
/*    order: 3;*/
/*    width: var(--side-item-width);*/
  flex: 2;
  padding-right: 20px;
  box-sizing: border-box;
}

#MainHeaderNav button {
  height: 40px;
  width: 100%;
  text-align: right;
  position: relative;
  z-index: 1000;  
}

#MainHeaderNav .menu-icon {
  cursor: pointer;
  display: block;
  width: 35px;
  height: 4.5px;
  background-color: #98ADFD;
  border-radius: 5px;
  margin: 4px auto;
  transition: 125ms;
}

#MainHeaderNav .isOpen .menu-icon {
  background-color: #fff;
}

#MainHeaderNav .menu-icon::before,
#MainHeaderNav .menu-icon::after {
  content: '';
  display: block;
  width: 35px;
  height: 4.5px;
  background-color: #98ADFD;
  border-radius: 5px;
  position: absolute;
  transition: 275ms;
}

#MainHeaderNav .isClosed .menu-icon::before {
  margin-top: -11px;
}

#MainHeaderNav .isClosed .menu-icon::after {
  margin-top: 11px;
}

#MainHeaderNav .isOpen .menu-icon::before {
  transform: rotate(45deg) translateY(0px);
}

#MainHeaderNav .isOpen .menu-icon::after {
  transform: rotate(-45deg) translateY(0px);
}    

#Footer {
  font-size: 60%;
  text-align: right;
}

#MenuCloser {
  /* left in for future debugging */
  /* background: rgba(50, 50, 50, 0.5); */
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}
